// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"登录","path":"/user/login","parentId":"1","id":"2"},"3":{"path":"/welcome","name":"欢迎","icon":"smile","parentId":"ant-design-pro-layout","id":"3"},"4":{"path":"/admin","name":"管理页","icon":"crown","access":"canAdmin","parentId":"ant-design-pro-layout","id":"4"},"5":{"path":"/admin","redirect":"/admin/sub-page","parentId":"4","id":"5"},"6":{"path":"/admin/sub-page","name":"二级管理页","parentId":"4","id":"6"},"7":{"name":"邀请码","icon":"table","path":"/invite/list","parentId":"ant-design-pro-layout","id":"7"},"8":{"name":"我的邀请","icon":"table","path":"/agent/list","parentId":"ant-design-pro-layout","id":"8"},"9":{"name":"提现账号","icon":"table","path":"/withdrawal/account/list","parentId":"ant-design-pro-layout","id":"9"},"10":{"name":"提现记录","icon":"table","path":"/withdrawal/record/list","parentId":"ant-design-pro-layout","id":"10"},"11":{"path":"/","redirect":"/welcome","parentId":"ant-design-pro-layout","id":"11"},"12":{"path":"*","layout":false,"id":"12"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Welcome" */'@/pages/Welcome.tsx')),
'4': React.lazy(() => import('./EmptyRoute')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Admin" */'@/pages/Admin.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__InviteList__index" */'@/pages/InviteList/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__AgentList__index" */'@/pages/AgentList/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__WithdrawalAccount__index" */'@/pages/WithdrawalAccount/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__WithdrawalRecord__index" */'@/pages/WithdrawalRecord/index.tsx')),
'11': React.lazy(() => import('./EmptyRoute')),
'12': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/opt/buildhome/repo/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
