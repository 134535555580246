import { QuestionCircleOutlined } from '@ant-design/icons';
import '@umijs/max';
import { useModel } from '@umijs/max';
import React from 'react';
export type SiderTheme = 'light' | 'dark';
export const SelectLang = () => {
  return (
    <UmiSelectLang
      style={{
        padding: 4,
      }}
    />
  );
};

export const Question = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  // return <span className="anticon">{currentUser?.blance}</span>;
  return (
    <div
      style={{
        display: 'flex',
      }}
    // onClick={() => {
    //   window.open('https://pro.ant.design/docs/getting-started');
    // }}
    >
      {/* <QuestionCircleOutlined /> */}
      余额： {currentUser?.balance || 0}
    </div>
  );
};
